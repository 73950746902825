<template>
  <div class="bg">
    <div class="login-box">
      <h1 class="login-title">登录</h1>
			<input class="phone-input" maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入手机号码"/>
      <button class="submit-button" type="submit">获取验证码</button>
		</div>
    <copyright></copyright>
  </div>
</template>

<script>
import Copyright from '../components/Copyright.vue'

export default {
  name: 'App',
  components: {
    Copyright
  }
}
</script>


<style scoped>
.bg {
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right: 0;
  width: 100vw;
  height: auto;
  background-color:#232e4c;
}
.login-box {
  position:absolute;
  padding: 36px;
  top:25vh;
  left:30vw;
  width: 40vw;
  height: 20vw;
  background-color:white;
  border-radius: 1vw;
}
.login-title{
  text-align: center;
  color: #232e4c;
  margin: 0, auto;
}
.phone-input {
  padding:10px;
  margin-top: 30px;
  border:0;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  border-radius: 3px;

  position: relative;
  width: 40%;
  left: 29%;
  font-size: 20px;
}
.phone-input:focus {
  border:0;
  outline: none;
  box-shadow:0 0 30px 3px #dbdbdb;
}
.submit-button {
  position: absolute;
  width: 38.5%;
  height: 12%;
  bottom: 25%;
  left: 31.5%;
  outline: none;

	border-width: 0px;
	border-radius: 3px; 
	background: #1E90FF;
  color: aliceblue;
  font-size: 20; 
}
.submit-button:active {
  background: #1E90FF;
  box-shadow:0 0 12px 1px #acd6ff;
}
</style>
