import Vue from 'vue'
import Axios from 'axios';//后台交互

import Home from './pages/Home.vue';
import Login from './pages/Login.vue';
import NoFound from './pages/404.vue';

Vue.prototype.$http=Axios

var debugModel = false
Axios.defaults.baseURL = debugModel ? "http://127.0.0.1:8640/" : "https://api.xuin.net/"
Vue.prototype.$wsUrl = debugModel ? "ws://127.0.0.1:8640/ws/qr" : "wss://api.xuin.net/wss/ws/qr"

const routes = {
  '/': Home,
  '/login': Login
}
const noFoundPage = NoFound;

new Vue({
  el: '#app',
  data: {
    currentRoute: window.location.pathname
  },
  computed: {
    ViewComponent () {
      return routes[this.currentRoute] || noFoundPage
    }
  },
  render (h) { return h(this.ViewComponent) }
})