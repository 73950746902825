<template>
  <div class="showPage">
    <div id="swiper" class="detail-swiper" v-swiper:mySwiper="swiperOption">
      <div class="swiper-wrapper">
        <div class="swiper-slide" :key="index" v-for="(banner,index) in banners">
          <img crossorigin="anonymous" :src="banner.src" v-on:load="imgloaded(banner.name)" :id="banner.name">
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <div id="qrcode" class="qrBox" v-bind:style="styleObject">
      <qrcode-vue class="qr" :value="qrData" size=180></qrcode-vue>
      <p class="label">使用</p>
      <img class="icon" src="alipay.webp">
      <img class="icon" src="wechat.webp">
      <p class="label">扫一扫</p>
    </div>
    <img id="fixImg" style="display: none;" class="fixImg" src="deviceFix.webp">
    <img id="selloutImg" style="display: none;" class="fixImg" src="sellout.webp">
  </div>
</template>

<script>
import { directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import QrcodeVue from 'qrcode.vue'

export default {
    directives: {
      swiper: directive
    },
    components: {
      QrcodeVue,
    },
    data() {
      return {
        token: "",
        banners: [],
        qrData:"",
        connection: null,
        swiperOption: {
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true
          },
          loop: true,
          autoplay: {
            disableOnInteraction: false,
          },
        },
        fixing: false,
        hasSellout: false,
        timeoutObj: null,
        pingTimeoutObj: null,
        pongTimeoutObj: null,
        loginTimeoutObj: null,
        styleObject: {
          top: 'auto',
          bottom: 'auto',
          right: 'auto',
          left: 'auto'
        },
        styleIndex: 0,
        deviceName: "eg9dFtURDHXHi2m8dSDU",
      }
    },
    beforeDestroy: function() {
      if(this.connection) {
        //console.log("销毁前，关闭ws连接")
        this.connection.close()
      }
    },
    methods: {
      startLogin: function() {
        this.$http({
          url: 'login',
          method: 'post',
          params:{
            "username":"xuin",
            "password":"root@8640",
            "phone":"18813975728",
          }
        }).then(res => {
          this.token = res.data.data.token
          this.getImgaeList()
          this.createWSConnect()
        }).catch(error => {
          console.log(error)
          this.loginTimeoutObj = setTimeout(this.startLogin, 3000)
        });
      },
      imgloaded: function(_name) {        
        var storageFiles = localStorage.getItem(_name) || null,
        curImg = document.getElementById(_name)
        
        // 检查数据，如果不存在，则创建一个本地存储
        if (storageFiles == null) {
          var imgCanvas = document.createElement("canvas"),
          imgContext = imgCanvas.getContext("2d");
          // 确保canvas尺寸和图片一致
          imgCanvas.width = curImg.naturalWidth;
          imgCanvas.height = curImg.naturalHeight;
          // 在canvas中绘制图片
          imgContext.drawImage(curImg, 0, 0, imgCanvas.width, imgCanvas.height);
          // 将图片保存为Data URI
          storageFiles = imgCanvas.toDataURL("image/webp");
          // 将JSON保存到本地存储中
          try {
            localStorage.setItem(_name, storageFiles);
          }
          catch (e) {
            console.log("Storage failed: " + e);
          }
        }
      },
      processInfo: function(_info) {
        var list = _info.product_list
        for(var i=0;i<list.length;++i) {
          var path = this.$http.defaults.baseURL + list[i]
          var filename = path.split('\\').pop().split('/').pop().split('.').shift();
          var storageFiles = localStorage.getItem(filename) || null
          if (storageFiles != null) {
            path = storageFiles
          }
          this.banners.push({"src": path,"name": filename})
        }
      },
      createWSConnect: function() {
        console.log("Starting connection to WebSocket Server")
        this.connection = new WebSocket(this.$wsUrl,[this.token,this.deviceName])
        this.connection.onmessage = this.updateQRData
        this.connection.onopen = this.wsOpen
        this.connection.onclose = this.wsClosed
        this.connection.onerror = this.wsError
      },
      updateQRData: function(_event) {
        var res = _event.data
        if (res == "pong") {
          clearTimeout(this.pongTimeoutObj)
          console.log("ws接收pong")
          return
        } else if (res == "trouble") {
            this.fixDevice()
        }  else if (res == "sellout") {
            this.sellout()
        } else {
          console.log("ws刷新二维码数据", _event)
          this.qrData = res;
        }
      },
      sendPing: function() {
        if(this.connection) {
          console.log("ws发送ping")
          this.connection.send("ping")
          this.pongTimeoutObj = setTimeout(this.closeWS, 10000)
        } else {
          clearTimeout(this.pingTimeoutObj)
        }
      },
      wsOpen: function() {
        this.clearTimeoutObj()
        this.pingTimeoutObj = setInterval(this.sendPing,5000);
      },
      wsError: function(event) {
        console.log("ws出错", event)
      },
      closeWS: function() {
        console.log("超时响应，自动断开连接，进入重连机制")
        clearTimeout(this.pongTimeoutObj)
        if (this.connection) {
          this.connection.close()
        }
        this.wsClosed()
      },
      wsClosed: function(event) {
        if (this.fixing || this.hasSellout) 
          return
        console.log("ws断开连接了",event)
        // 2s后执行重连尝试
        this.clearTimeoutObj()
        if (this.connection) {
          this.connection = null
        }
        this.timeoutObj = setTimeout(this.createWSConnect,2000);
      },
      clearTimeoutObj: function() {
        if (this.pingTimeoutObj) {
          clearTimeout(this.pingTimeoutObj)
        }
        if (this.timeoutObj) {
          clearTimeout(this.timeoutObj)
        }
      },
      fixDevice: function() {
        this.fixing = true
        document.getElementById("swiper").style.display = "none"
        document.getElementById("qrcode").style.display = "none"
        document.getElementById("fixImg").style.display = "inline"
        this.stopAllTimer();
      },
      sellout: function() {
        this.hasSellout = true
        document.getElementById("swiper").style.display = "none"
        document.getElementById("qrcode").style.display = "none"
        document.getElementById("selloutImg").style.display = "inline"
        this.stopAllTimer()
      },
      stopAllTimer: function() {
        if (this.pingTimeoutObj) {
          clearTimeout(this.pingTimeoutObj)
        }
        if (this.pongTimeoutObj) {
          clearTimeout(this.pongTimeoutObj)
        }
        if (this.timeoutObj) {
          clearTimeout(this.timeoutObj)
        }
        if (this.connection) {
          this.connection.close()
        }
      },
      getImgaeList: function() {
        this.$http({
          url: '/api/v1/getImgList',
          method: 'get',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': this.token, 
          }
        }).then(res => {
          this.processInfo(res.data.data)
        }).catch(error => {
          console.log(error)
        });
      },
      qrLeftTop: function() {
        this.styleObject.top = '10px'
        this.styleObject.bottom = 'auto'
        this.styleObject.right = 'auto'
        this.styleObject.left = '10px'
      },
      qrLeftBottom: function() {
        this.styleObject.top = 'auto'
        this.styleObject.bottom = '10px'
        this.styleObject.right = 'auto'
        this.styleObject.left = '10px'
      },
      qrRightTop: function() {
        this.styleObject.top = '10px'
        this.styleObject.bottom = 'auto'
        this.styleObject.right = '10px'
        this.styleObject.left = 'auto'
      },
      qrRightBottom: function() {
        this.styleObject.top = 'auto'
        this.styleObject.bottom = '10px'
        this.styleObject.right = '10px'
        this.styleObject.left = 'auto'
      }, 
      keyboardState: function(opened) {
        console.log("keyboard is open: ", opened)
      },
      batteryLevel: function(level) {
        console.log("电量变化", level)
      },
      networkStatus: function(status) {
        console.log("网络状态", status)
      }
    },
    mounted () {
      window.keyboardState = this.keyboardState
      window.batteryLevel = this.batteryLevel
      window.networkStatus = this.networkStatus
    },
    created: function() {
      this.startLogin()
      this.qrLeftTop()
      setInterval(()=>{
        this.styleIndex++
        if (this.styleIndex == 4) {
          this.styleIndex = 0
        }
        switch(this.styleIndex) {
          case 0:
            this.qrLeftTop()
            break;
          case 1:
            this.qrLeftBottom()
            break;
          case 2:
            this.qrRightBottom()
            break;
          case 3:
            this.qrRightTop()
            break;
        }
      }, 300000)
    },
}
</script>

<style scoped>
img {
  height: auto;
  width: 100vw;
  z-index: 0;
}
.showPage{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  touch-action: none;
}
/* .detail-swiper{
  pointer-events:none;
} */
.qrBox {
  z-index: 2;
  position: fixed;
  /* top: 10px;
  right: 10px; */
  /* top: 10px;
  left: 10px; */
  /* bottom: 10px;
  right: 10px;
  top: auto; */
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background:white;
  font-size: 16px;
}
.fixImg {
  z-index: 3;
  position: fixed;
}
.qr{
  padding-bottom: 5px;
}
.label {
  display:inline-block;
  padding-right: 10px;
}
.icon {
  vertical-align: middle;
  display:inline-block;
  width: 30px;
  height: 30px;
  padding-right: 10px;
}
</style>
